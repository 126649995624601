
        import _47opt_47buildhome_47repo_47app_47pages_47ai_46vue from "/opt/buildhome/repo/app/pages/ai.vue";
import _47opt_47buildhome_47repo_47app_47pages_47ama_46vue from "/opt/buildhome/repo/app/pages/ama.vue";
import _47opt_47buildhome_47repo_47app_47pages_47bio_46vue from "/opt/buildhome/repo/app/pages/bio.vue";
import _47opt_47buildhome_47repo_47app_47pages_47blog_47_91article_93_46vue from "/opt/buildhome/repo/app/pages/blog/[article].vue";
import _47opt_47buildhome_47repo_47app_47pages_47blog_47index_46vue from "/opt/buildhome/repo/app/pages/blog/index.vue";
import _47opt_47buildhome_47repo_47app_47pages_47feed_46vue from "/opt/buildhome/repo/app/pages/feed.vue";
import _47opt_47buildhome_47repo_47app_47pages_47feedback_46vue from "/opt/buildhome/repo/app/pages/feedback.vue";
import _47opt_47buildhome_47repo_47app_47pages_47index_46vue from "/opt/buildhome/repo/app/pages/index.vue";
import _47opt_47buildhome_47repo_47app_47pages_47live_46vue from "/opt/buildhome/repo/app/pages/live.vue";
import _47opt_47buildhome_47repo_47app_47pages_47talks_46vue from "/opt/buildhome/repo/app/pages/talks.vue";
import _47opt_47buildhome_47repo_47app_47pages_47uses_46vue from "/opt/buildhome/repo/app/pages/uses.vue";
import _47opt_47buildhome_47repo_47app_47pages_47voted_46vue from "/opt/buildhome/repo/app/pages/voted.vue";
import _47opt_47buildhome_47repo_47app_47pages_47work_46vue from "/opt/buildhome/repo/app/pages/work.vue";
        export default [
  {
    path: "/ai",
    component: _47opt_47buildhome_47repo_47app_47pages_47ai_46vue,
    meta: {}
  },
  {
    path: "/ama",
    component: _47opt_47buildhome_47repo_47app_47pages_47ama_46vue,
    meta: {}
  },
  {
    path: "/bio",
    component: _47opt_47buildhome_47repo_47app_47pages_47bio_46vue,
    meta: {}
  },
  {
    path: /\/blog\/(?<article>.+)/,
    component: _47opt_47buildhome_47repo_47app_47pages_47blog_47_91article_93_46vue,
    meta: {}
  },
  {
    path: "/blog",
    component: _47opt_47buildhome_47repo_47app_47pages_47blog_47index_46vue,
    meta: {"title":"Blog"}
  },
  {
    path: "/feed",
    component: _47opt_47buildhome_47repo_47app_47pages_47feed_46vue,
    meta: {}
  },
  {
    path: "/feedback",
    component: _47opt_47buildhome_47repo_47app_47pages_47feedback_46vue,
    meta: {}
  },
  {
    path: "/",
    component: _47opt_47buildhome_47repo_47app_47pages_47index_46vue,
    meta: {}
  },
  {
    path: "/live",
    component: _47opt_47buildhome_47repo_47app_47pages_47live_46vue,
    meta: {}
  },
  {
    path: "/talks",
    component: _47opt_47buildhome_47repo_47app_47pages_47talks_46vue,
    meta: {"title":"Talks"}
  },
  {
    path: "/uses",
    component: _47opt_47buildhome_47repo_47app_47pages_47uses_46vue,
    meta: {"title":"Uses"}
  },
  {
    path: "/voted",
    component: _47opt_47buildhome_47repo_47app_47pages_47voted_46vue,
    meta: {}
  },
  {
    path: "/work",
    component: _47opt_47buildhome_47repo_47app_47pages_47work_46vue,
    meta: {"title":"Work"}
  }
]